import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { format, parseISO } from "date-fns"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function onKeyPress(e: KeyboardEvent | any, fn: () => void) {
  e.stopPropagation()
  if (e.key === "Enter") {
    fn()
  }
}

/**
 * Formats a date string to "Monday 05, September 2021".
 * @param dateString - The date string to format.
 * @returns The formatted date string.
 */
export const formatDate = (dateString: string): string => {
  const date = parseISO(dateString)
  return format(date, "EEEE dd, MMMM yyyy")
}
